<template>
  <b-card v-if="userData">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="FileTextIcon" size="19" />
      <h4 class="mb-0 ml-50">{{ $t('usersModel.title') }}</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit(userData))">
        <b-row class="mt-1" align-v="center">
          <!-- Field: Name -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required|min:3"
            >
              <b-form-group :label="$t('usersModel.name')" label-for="name">
                <b-form-input
                  v-model="userData.name"
                  class="form-control"
                  :placeholder="$t('usersPlaceholders.name')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Last Name -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required|min:3"
            >
              <b-form-group
                :label="$t('usersModel.last')"
                label-for="Last name"
              >
                <b-form-input
                  v-model="userData.lastName"
                  class="form-control"
                  :placeholder="$t('usersPlaceholders.last')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Is Active -->
          <b-col>
            <b-row class="mt-1 ml-1">
              <p class="mr-1" style="font-weight: bold">
                {{ $t('usersModel.isActive') }}
              </p>
              <b-form-checkbox
                v-model="userData.isActive"
                name="check-button"
                switch
              >
                <span style="font-weight: 500">
                  {{
                    userData.isActive
                      ? $t('usersStatus.active.yes')
                      : $t('usersStatus.active.no')
                  }}
                </span>
              </b-form-checkbox>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- Field: DNI -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="DNI"
              rules="required"
            >
              <b-form-group :label="$t('usersModel.dni')" label-for="DNI">
                <b-form-input
                  id="dni"
                  v-model="userData.dni"
                  :placeholder="$t('usersPlaceholders.dni')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group :label="$t('usersModel.email')" label-for="email">
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  placeholder="johndoe@email.com"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Phone -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Phone"
              rules="required|min:4"
            >
              <b-form-group :label="$t('usersModel.phone')" label-for="Phone">
                <b-form-input
                  id="phone"
                  v-model="userData.phone"
                  :placeholder="$t('usersPlaceholders.phone')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Preffered Language -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Preffered Language"
              rules="required"
            >
              <b-form-group
                :label="$t('usersModel.prefferedLanguage')"
                label-for="Preffered Language"
              >
                <b-form-select
                  id="prefferedLanguage"
                  :options="languages"
                  v-model="userData.prefferedLanguage"
                  placeholder="Preffered Language"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Role -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Role"
              rules="required"
            >
              <b-form-group :label="$t('usersModel.role')" label-for="Role">
                <b-form-select
                  id="role"
                  :options="roles"
                  v-model="userData.role"
                  placeholder="Role"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2 pr-1" align-h="end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :to="{ name: 'users-list' }"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
            ><b-spinner v-if="loading" small :label="$t('commons.loading')" />
            <span v-else> {{ $t('buttons.save') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  <SpinnerLoading v-else />
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'
import usersStoreModule from './usersStoreModule'
import UserModel from '@/models/user.model'
import useUsersList from './useUsersList'
import SpinnerLoading from '@/components/SpinnerLoading.vue'
import i18n from '@/libs/i18n/index'
import { localize } from 'vee-validate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    SpinnerLoading,
  },

  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },

  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      localize(newVal)
    },
  },

  computed: {
    loading() {
      return this.$store.state['app-users'].loading
    },
  },

  setup() {
    const blankuserData = new UserModel({
      isActive: false,
      prefferedLanguage: null,
      role: null,
    })
    const userData = ref(null)

    const user_APP_STORE_MODULE_NAME = 'app-users'

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankuserData))
    }

    // Register module
    if (!store.hasModule(user_APP_STORE_MODULE_NAME)) {
      store.registerModule(user_APP_STORE_MODULE_NAME, usersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(user_APP_STORE_MODULE_NAME))
        store.unregisterModule(user_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-users/fetchOneUser', router.currentRoute.params.id)
        .then((response) => {
          userData.value = response
          userData.value.isActive = userData.value.isActive === 1 ? true : false
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    } else {
      userData.value = blankuserData
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    const { onSubmit } = useUsersList()

    const languages = computed(() => {
      return [
        { value: null, text: i18n.t('select.default') },
        { value: 'english', text: 'English' },
        { value: 'spanish', text: 'Spanish' },
        { value: 'swedish', text: 'Swedish' },
      ]
    })

    const roles = computed(() => {
      return [
        { value: null, text: i18n.t('select.default') },
        { value: 'user', text: 'User' },
        { value: 'superadmin', text: 'Super Admin' },
        { value: 'salesperson', text: 'Sales Person' },
        { value: 'applicant', text: 'Applicant' },
        { value: 'propertyowner', text: 'Property Owner' },
      ]
    })

    return {
      languages,
      roles,
      userData,
      refFormObserver,
      getValidationState,
      resetForm,

      onSubmit,
    }
  },
}
</script>

<style></style>
